import { css } from '@emotion/react';

const style = css`
    line-height: 175.18%;
    font-weight: 600;
    text-transform: capitalize;
`;

export const h1 = css`
    ${style};
    font-size: 2rem;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const h2 = css`
    ${style};
    font-size: 1.75rem;

    @media (min-width: 1280px) {
        font-size: 2.25rem;
    }
`;

export const h3 = css`
    ${style};
    font-size: 1.5rem;

    @media (min-width: 1280px) {
        font-size: 1.75rem;
    }
`;

export const h4 = css`
    ${style};
    font-size: 1.25rem;

    @media (min-width: 1280px) {
        font-size: 1.345rem;
    }
`;

export const h5 = css`
    ${style};
    font-size: 1rem;

    @media (min-width: 1280px) {
        font-size: 1.0625rem;
    }
`;

export const smTxt = css`
    font-size: 0.875rem;

    @media (min-width: 1280px) {
        font-size: 1rem;
    }
`;

export const lgTxt = css`
    font-size: 1.125rem;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;

export const leftLine = css`
    padding-left: 8px;
    border-left: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(104.04deg, #ffc339 0%, #0ac3ec 100%);

    @media (min-width: 1280px) {
        border-left-width: 4px;
        padding: 8px 0 8px 16px;
    }
`;
